import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import logo from "../img/logo.svg";
import icecone from "../img/icecone_64x64.png";
import OpeningTimes from "../pages/opening-times";
import Contact from "../pages/contact/new-contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

export const IndexPageTemplateNew = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div
    style={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-around",
      // height: "100vh",
    }}
  >
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        {/* <figure class="image is-64x64">
          <img
            src={icecone}
            alt="Bulma: Free, open source, and modern CSS framework based on Flexbox"
            width="64"
            height="64"
          />
        </figure> */}

        {/* <a
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a> */}
      </div>
    </nav>

    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img src={logo} alt="Ijssalonpavee" style={{ width: "222px" }} />
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/*       <h1
        className="has-text-weight-bold has-text-centered is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
        style={{
          color: "rgb(255, 140, 21)",
          lineHeight: "1",
          padding: "0.25em",
        }}
      >
        Welkom <br /> wij zijn elke dag open
      </h1>
 */}{" "}
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    ></div>
    <OpeningTimes />
    {/* {
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 className="has-text-centered title is-size-1">
          Tot ziens in maart 2022!
        </h1>
      </div>
    } */}

    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1rem",
        flexDirection: "column",
      }}
    >
      <span>Katendrechtse Lagedijk 1, 3081 ZG Rotterdam</span>
      <div>
        <FontAwesomeIcon
          style={{
            margin: "10px",
            cursor: "pointer",
          }}
          icon={faInstagram}
          size="2x"
          onClick={() =>
            window.open("https://www.instagram.com/ijssalonpavee/", "_blank")
          }
        />
        <FontAwesomeIcon
          style={{
            margin: "10px",
            cursor: "pointer",
          }}
          icon={faFacebookSquare}
          size="2x"
          onClick={() =>
            window.open(
              "https://www.facebook.com/Ijssalon-Pave%C3%A9-108532120892000/",
              "_blank"
            )
          }
        />
      </div>
    </div>
    {/* <Contact /> */}
  </div>
);

IndexPageTemplateNew.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPageNew = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplateNew
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPageNew.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPageNew;

export const pageQuery = graphql`
  query IndexPageTemplateNew {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page-new" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
